import { useEffect, useState } from "react";
import PageContainer from "./PageContainer";
import { APP_STORE_URL } from "./Constants";

export default function Home() {
  // const [scrollDirection, setScrollDirection] = useState("down");
  // const [backgroundPosition, setBackgroundPosition] = useState("50% 50%");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const useDesktopDesign = screenWidth >= 1080;

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once after the component mounts

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setScrollDirection((prevDirection) => {
  //       return prevDirection === "down" ? "up" : "down";
  //     });
  //   }, 20000); // Adjust the interval according to your preference

  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   setBackgroundPosition(scrollDirection === "down" ? "80% 100%" : "50% 20%");
  // }, [scrollDirection]);

  return (
    <PageContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",

          alignItems: "center",
          overflow: "hidden",
          width: "100%",
          justifyContent: "center",
          // flexWrap: "wrap",
          //   maxWidth: 1440,
        }}
      >
        <div
          style={{
            maxWidth: 640,
            padding: useDesktopDesign ? "0 0 0 3rem" : "0 1rem 0 1rem",
          }}
        >
          <div
            style={{
              // maxWidth: 720,
              margin: "0 0 0 auto",
              textAlign: useDesktopDesign ? "left" : "center",
              display: "flex",
              flexDirection: "column",
              alignItems: useDesktopDesign ? "flex-start" : "center",
            }}
          >
            <header
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: useDesktopDesign ? "2rem 0" : "4rem 0 ",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: useDesktopDesign ? "flex-start" : "center",

                  gap: "1rem",
                }}
              >
                <img
                  src={require("../assets/images/plover_web_light_1024.png")}
                  style={{ width: "6rem" }}
                />
                <span
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    fontWeight: 600,
                    color: "var(--primary-500)",
                    fontSize: "2rem",
                  }}
                >
                  Plover Audio
                </span>
              </div>
            </header>
            <a
              href={APP_STORE_URL}
              className="button"
              // style={{ margin: "1rem 0" }}
            >
              <img
                alt="Download on the App Store"
                aria-label="Download on the App Store"
                src={require("../assets/images/app_store_button.png")}
                style={{ width: "12rem" }}
              />
            </a>
            <h1
              style={{
                marginTop: "2rem",
                fontSize: "2rem",
              }}
            >
              Listen to 1,9300+ LibriVox audiobooks on your phone for free
            </h1>

            <p className="homepage-subtitle">No ads.</p>
            <p className="homepage-subtitle">
              No max number of audiobooks per month.
            </p>

            <p style={{ marginTop: "2rem", marginBottom: 0 }}>
              Plover <span className="plover-gold-text">Gold</span> required for
              certain app features. Learn more in the app.
            </p>
          </div>
        </div>
        {useDesktopDesign ? (
          <div
            style={{
              marginLeft: "2rem",
              height: "calc(100vh - 5rem)",
              width: "50%",
              maxWidth: 700,
              // position: "relative",
              backgroundImage: `url(${require("../assets/images/screens.png")})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              transition: "background-position 20s ", // Adjust the transition timing as needed
              backgroundPosition: "50% 50%",
            }}
          ></div>
        ) : null}
      </div>
      {!useDesktopDesign ? (
        <div
          className="hide-scrollbar"
          style={{ height: 440, width: "100%", overflow: "scroll" }}
        >
          <img
            src={require("../assets/images/screens_2.png")}
            style={{ height: "100%" }}
          />
        </div>
      ) : null}
    </PageContainer>
  );
}
