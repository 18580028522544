import Home from "./Home";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CopyableText from "./CopyableText";
import { Apple, FileText, Mail } from "lucide-react";
import { APP_STORE_URL } from "./Constants";

function PageFooter() {
  return (
    <footer className="page-footer">
      <a className="page-footer-section" href={APP_STORE_URL} target="_blank">
        <Apple size={20} absoluteStrokeWidth />
        <span>Download on the App Store</span>
      </a>
      <span className="page-footer-section">
        <Mail size={20} absoluteStrokeWidth />
        <CopyableText text="help@plover-audio.com" />
      </span>
      <a
        className="page-footer-section"
        href="https://plover-audio.notion.site/Privacy-Policy-e4b4b7f15fd141e4986ba9209216af24"
        target="_blank"
      >
        <FileText size={20} absoluteStrokeWidth />
        <span>Privacy Policy</span>
      </a>
      <a
        className="page-footer-section"
        href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
        target="_blank"
      >
        <FileText size={20} absoluteStrokeWidth />
        <span>Terms of Service</span>
      </a>
    </footer>
  );
}

function App() {
  const routes = (
    <Router>
      {/* <ScrollToTop /> */}
      <Routes>
        <Route index path="/" element={<Home />} />
        {/* <Route index path="/privacy_policy" element={<PrivacyPolicy />} /> */}
      </Routes>
    </Router>
  );

  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {routes}
      <PageFooter />
    </div>
  );
}

export default App;
