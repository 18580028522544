import React, { useState } from "react";
import { Copy, CircleCheck } from "lucide-react";

interface CopyableTextProps {
  text: string;
  className?: string;
}

const CopyableText: React.FC<CopyableTextProps> = ({ text, className }) => {
  const [showCopyIcon, setShowCopyIcon] = useState(false);
  const [showCheckIcon, setShowCheckIcon] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setShowCheckIcon(true);

    setTimeout(() => {
      setShowCheckIcon(false);
    }, 3000);
  };

  return (
    <span
      className={"copyable-text"}
      onMouseEnter={() => setShowCopyIcon(true)}
      onMouseLeave={() => setShowCopyIcon(false)}
      onClick={handleCopy}
    >
      {text}

      {showCheckIcon ? (
        <CircleCheck
          size={16}
          color="var(--primary-500)"
          style={{ marginLeft: 4 }}
          absoluteStrokeWidth
        />
      ) : showCopyIcon ? (
        <Copy
          size={16}
          color="var(--primary-500)"
          absoluteStrokeWidth
          style={{ marginLeft: 4 }}
        />
      ) : null}
    </span>
  );
};

export default CopyableText;
